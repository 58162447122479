<template>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh"
                      class="courseEvaluate">
        <h6>全部评论</h6>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad">
            <div class="courseEvaluate_item"
                 v-for="item in list" :key="item.id">
                <div class="imgs">
                    <baseAvator :url="item.avatar"/>
                </div>
                <div class="evaluate_item">
                    <div class="names">
                        <span>{{item.userName || '***'}}</span>
                    </div>
                    <p class="contents" v-html="item.content"></p>
                    <div class="footers">
                        发表于 {{item.createTime}}
                    </div>
                </div>
            </div>
        </van-list>
    </van-pull-refresh>
</template>

<script>
import DropDownRefresh from './../../../mixin/DropDownRefresh';
import api from './../../../api/common';

export default {
    name: "courseEvaluate",
    mixins: [DropDownRefresh],
    data() {
        return {
            listQuery: {
                courseId: this.$route.query.id,
                current: 1,
                size: 10
            }
        };
    },
    methods: {
        // 查询首页课程评价列表
        getList() {
            api.getEvalQueryHomeList(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    }
}
</script>
<style lang="less">
    .contents {
        width: 100%;
        table {
            max-width: 100% !important;
            width: 100% !important;
            td {
                word-break: break-all;
            }
            td > table {
                width: 100% !important;
            }
        }
        img {
            max-width: 100% !important;
            height: 100%;
        }
    }
</style>

<style scoped lang="less">
    .courseEvaluate {
        padding: 32px 40px;
        h6 {
            font-size: 32px;
            font-weight: 600;
            color: #353535;
            line-height: 32px;
            margin-bottom: 24px;
        }
        .courseEvaluate_item {
            padding: 36px 0 24px 64px;
            border-bottom: 2px solid #F5F5F5;
            position: relative;
            .imgs {
                position: absolute;
                left: 0;
                top: 24px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
            }
        }
        .evaluate_item {
            .names {
                display: flex;
                justify-content: space-between;
                span {
                    font-size: 24px;
                    font-weight: 400;
                    color: #969696;
                    line-height: 24px;
                }
            }
            .contents {
                margin-top: 20px;
                font-size: 28px;
                font-weight: 400;
                color: #353535;
                word-break: break-all;
            }
            .footers {
                margin-top: 20px;
                text-align: right;
                font-size: 20px;
                font-weight: 400;
                color: #969696;
                line-height: 20px;
            }
        }
    }
</style>