<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
            <div class="imgs">
                <baseImage :url="details.courseCover"/>
            </div>
            <van-tabs v-model="activeName" class="custom_tabs">
                <van-tab title="课程简介" name="a"/>
                <van-tab title="课程安排" name="b"/>
                <van-tab title="课程评价" name="c"/>
            </van-tabs>
        </template>
        <div class="courseDetails">
            <van-tabs v-model="activeName" class="custom_tabs spaeil">
                <van-tab title="课程简介" name="a">
                    <CourseIntro :courseDetail="details"/>
                </van-tab>
                <van-tab title="课程安排" name="b">
                    <div class="courseArrange">
                        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                            <van-list
                                    v-model="loading"
                                    :finished="finished"
                                    finished-text="没有更多了"
                                    @load="onLoad">
                                <BarList :list="list" @callMore="(item)=>{callMore(item, '/liveDetails')}"/>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
                <van-tab title="课程评价" name="c">
                    <CourseEvaluate/>
                </van-tab>
            </van-tabs>
        </div>
    </baseContainer>
</template>

<script>
import HeadNav from '../../components/headNav';
import CourseIntro from './live/courseIntro';
import CourseEvaluate from './course/courseEvaluate';
import BarList from './../home/components/BarList';
import CacheTabs from './../../mixin/CacheTabs';
import DropDownRefresh from './../../mixin/DropDownRefresh';
import api from './../../api/common';
// import apiHome from './../../api/home';

export default {
    name: "courseDetails",
    components: {
        HeadNav,
        CourseIntro,
        BarList,
        CourseEvaluate
    },
    mixins: [CacheTabs, DropDownRefresh],
    data () {
        return {
            details: {},
            listQuery: {
                courseId: this.$route.query.id,
                current: 1,
                size: 5
            }
        }
    },
    methods: {
        callMore (item, url) {
            this.$utils.callMores(item, url, this.$route.query.noCheck);
        },
        // 通过id查询单个课程信息
        getDetails () {
            api.getCourseDetails(this.$route.query.id).then(res => {
                this.details = res;
            });
        },
        // 课程安排
        getList () {
            api.getQueryCourseLive(this.listQuery).then(res => {
                if (res) {
                    this.onEnd(res.total, res.records);
                }
            });
        }
    },
    mounted () {
        this.getDetails();
    }
}
</script>

<style scoped lang="less">
    .courseDetails {
        background: #fff;
        /deep/ .BarList_item {
            box-shadow: none;
            border-bottom: 1px solid #F5F5F5;
            padding: 32px 0;
            margin-top: 0;
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .imgs {
        width: 100%;
        height: 400px;
    }

    .courseArrange {
        padding: 0 40px 40px;
    }
</style>